/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2020-12-31 15:34:00
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-06 10:55:39
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listProductBaseCategorySpec = params => axios({
    url: '/api/product/spec/page/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addProductBaseCategorySpec = params => axios({
    url: '/api/product/spec/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editProductBaseCategorySpec = params => axios({
    url: '/api/product/spec/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delProductBaseCategorySpec = params => axios({
    url:'/api/product/spec/del/spec/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdProductBaseCategorySpec = params => axios({
    url: '/api/product/system/productBaseCategorySpec/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
