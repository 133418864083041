/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2020-12-31 15:34:00
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-03 10:00:48
 */
export const columns = [
  // {
  //     title: '序号',
  //     dataIndex: 'index',
  //     key: 'index',
  //     /*width:150,*/
  //     align:'center',
  //     ellipsis:true,
  //     scopedSlots: { customRender: 'index'}
  // },
  {
    title: '规格名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    ellipsis: true,
    width: 200,
    fixed: 'left',
    scopedSlots: { customRender: 'name'}
  },
  {
    title: '规格参数',
    dataIndex: 'specValueName',
    key: 'specValueName',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '关联分类',
    dataIndex: 'categoryName',
    key: 'categoryName',
    /*width:150,*/
    align: 'center',
    ellipsis: true,
  },
  {
    title: '状态',
    dataIndex: 'flagEnable',
    key: 'flagEnable',
    /*width:150,*/
    align: 'center',
    ellipsis: true,
    scopedSlots: {customRender: 'flagEnable'}
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '操作时间',
    dataIndex: 'modifyTime',
    key: 'modifyTime',
    /*width:150,*/
    width: 200,
    align: 'center',
    ellipsis: true
  },
  // {
  //     title: '操作',
  //     dataIndex: 'action',
  //     key: 'action',
  //     width:150,
  //     align:'center',
  //     ellipsis:true,
  //     scopedSlots: {
  //         customRender: 'action'
  //     }
  // }
]
